@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  font-family: "Nunito", sans-serif;
}

/* input{
  height: 38px !important;
} */

.login {
  position: absolute;
  width: 600px;
  height: 650px;
  left: 50%;
  top: 50%;
  background: #ffffff;
  box-shadow: 0px 10px 30px rgba(17, 38, 146, 0.05);
  border-radius: 8px;
  transform: translate(-50%, -50%);
  padding: 50px;
}

.login .login-logo {
  text-align: center;
}

.login .login-logo img {
  width: 256px;
}

.login .login-head {
  font-weight: 700 !important;
  font-size: 33px;
  line-height: 130%;
}

.login .login-head h6 {
  color: #8a92a6;
  margin-top: 20px;
}

.login .login-body {
  row-gap: 15px;
}

.input-field {
  padding: 8px 16px;
  height: 60px;
  background: #e5e8ee;
  border-radius: 8px;
  border: none;
  outline: none;
  margin: 10px 0px;
}

.input-label {
  color: #8a92a6;
  font-size: 16px;
  font-weight: bold;
}

.input-link {
  color: #e51916;
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;
}

.custom-button {
  background: #e51916 !important;
  border-radius: 4px;
  padding: 8px 24px;
  width: 190px;
  height: 45px;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.login-body .users-label input {
  width: 100% !important;
  padding-left: 15px;
}

@media screen and (max-width: 768px) {
  .login {
    width: 90%;
    padding: 15px;
  }
}

/* ========================><======================== */

.sidebar-close-btn {
  width: 25px;
  height: 25px;
  position: absolute;
  left: 200px;
  top: 20px;
}

.sidebar-close-btn img {
  width: 100%;
  height: 100%;
}

.sidebar {
  background-color: #06163A;
  width: 100%;
  box-shadow: 1px 0px 30px #f4f4f4;
  text-align: center;
  height: 100vh;
  overflow-y: auto;
}

.sidebar::-webkit-scrollbar {
  display: none;
}

.sidebar-links {
  margin: 0px 20px;
  border-bottom: 2px solid rgba(255, 255, 255, 0.5);
  margin-bottom: 10px;
  min-height: 80vh;
  margin-top: 30px;
}

.layout-siderbar {
  z-index: 222;
}

.children-bg {
  background-color: #f0f1f6;
  width: 100%;
  height: 100vh;
  overflow: auto;
  /* padding: 115px 40px 40px 40px; */
  position: relative;
}

/* .sidebar-links {
  margin: 0px 20px;
  border-top: 1px solid #ededed;
  min-height: 80vh;
} */

.sidebar-links button img {
  margin-right: 14px;
  margin-bottom: 0px;
}

.sidebar-links .disabel {
  margin-top: 6px;
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
  width: 100%;
  display: flex;
  /* padding-left: 25px !important; */
  align-items: center;
  padding: 13.5px 0px;
  border-radius: 10px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: rgba(255, 255, 255, 0.65);
  text-decoration: none !important;
}

.sidebar-links .active {
  background-color: #132856 !important;
  text-decoration: none !important;
  color: #fff !important;
}

/* .sidebar-links .disabel.btn-primary:hover { */
/* color: #6B7280 !important; */
/* color: #696969 !important; */
/* } */

/* .sidebar-links .active.btn-primary:hover {
  color: #132856 !important;
} */

.sidebar-links a {
  text-decoration: none;
}

.authlayout-dashborad {
  display: grid;
  height: 100vh;
}

.authlayout-dashborad-1 {
  grid-template-columns: 255px auto;
}

.header {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 65px;
  background: #ffffff;
  box-shadow: 0px 10px 30px rgba(17, 38, 146, 0.05);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  z-index: 2;
}

.header .bars {
  width: 30px;
  height: 30px;
  cursor: pointer;
  margin-top: 10px;
}

.header .profile {
  display: flex;
  align-items: center;
  column-gap: 20px;
}

.header .profile img {
  width: 45px;
  height: 45px;
  border-radius: 50%;
}

.header .profile .notification {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  cursor: pointer;
}

.header .profile p {
  font-size: 10px;
  margin: 0 !important;
  padding: 0;
  width: 100%;
  padding: 8px 0px;
  text-align: center;
}

.header .profile p:hover {
  background-color: #b6b3b333;
}

.header .profile label {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #1f2937;
}

.profileAvtar {
  height: 60px;
  width: 60px;
  border-radius: 50%;

}

.logo {
  display: flex;
  justify-content: center;
  border-bottom: 2px solid rgba(255, 255, 255, 0.5);
  align-items: center;
  padding: 10px 10px;
}


/* .showsidebar{

} */
.disabel .icons-img {
  width: 53px;
}

.parent-dashboard {
  width: 100%;
  padding: 25px;
}

.users-form {
  width: 100%;
  margin: 0 !important;
}

.set-page-heade {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.set-page-heade h2 {
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 5px;
}

.adduser-btn {
  border: none !important;
  background-color: #06163A !important;
  font-family: "nunito-regular", sans-serif !important;
  font-size: 14px !important;
  color: #FFFFFF !important;
  padding: 8px 25px !important;
  border-radius: 3px !important;
}

.contractor-btn {
  border: none !important;
  background-color: #06163A !important;
  font-family: "nunito-regular", sans-serif !important;
  font-size: 14px !important;
  color: #FFFFFF !important;
  padding: 8px 15px !important;
  border-radius: 3px !important;
}

.ag-theme-alpine .ag-row {
  background-color: transparent !important;
  margin: 0px !important;
}

.delete-edit-buttons {
  display: flex;
  align-items: center;
  height: 100%;
}

.edit-button {
  background-color: #06163A !important;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  border: none !important;
  width: 37px;
  height: 37px;
}

.display-contractor {
  background-color: transparent;
  /* box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.05); */
  border-radius: 5px;
  border: none !important;
  width: 37px;
  height: 37px;
}

.display-contractor img {
  width: 30px;
  height: 25px;
}

.delete-button {
  background-color: #E51916;
  border: none !important;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  width: 37px;
  height: 37px;
}

.edit-button img {
  vertical-align: unset;
}

.delete-button img {
  vertical-align: unset;
}

.ag-theme-alpine .ag-ltr .ag-cell {
  outline: none !important;
  border: none !important;
}

.ag-header-viewport {
  background: #e3e3e3 !important;
}

.ag-theme-alpine .ag-paging-panel {
  padding: 0px 40px !important;
}

/* ===================>Pagination Select<======================== */

.set-pagination-dropdaun {
  position: absolute;
  bottom: 0px;
  left: 30px;
  display: flex;
}

.pagination-select {
  height: 22px;
}

/* ===========================>   <=========================== */

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::after {
  left: -20px !important;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  left: -20px !important;
}

.react-datepicker__input-container input {
  border: 1px solid #c4c5c6 !important;
  padding: 0 10px;
}

/* ================================================================================ */
.loading {
  position: relative;
  pointer-events: none;
}

.loaderHolder {
  background-color: rgba(170, 178, 218, 0.2);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  height: 100vh;
  width: 100%;
}

.loader {
  height: 150px;
  width: 150px;
}

.form-check {
  display: flex !important;
  align-items: center;
}

.checkbox-input input {
  width: 22px !important;
  height: 22px !important;
  border: 2px solid #1d2226 !important;
  box-shadow: none !important;
}

.checkbox-input label {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #1d2226;
  margin-left: 10px;
}

/* .form-check-input:checked[type=checkbox] {
  background-image: url(./assets/image/right-arrow-55555.png) !important;
} */

.checkbox-input input[type="checkbox"]:checked {
  background-color: transparent !important;
}

.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before {
  left: -20px !important;
}

.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after {
  left: -20px !important;
}

/* ============>   <============= */

.date-picker {
  position: relative;
}

.sm-picker .date-picker .datepickericon {
  position: absolute;
  top: 43px;
  right: 5% !important;
}

.datepickericon {
  position: absolute;
  top: 38px !important;
  right: 22% !important;
  opacity: 0.5;
  width: 22px;
  cursor: pointer;
}

.img-set {
  right: 5% !important;
}

.header-select select {
  width: 150px;
  /* height: 50px; */
  border: 1px solid #06163A !important;
  background-color: transparent !important;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 8px;
}

.header-select img {
  position: absolute;
  top: 14px;
  right: 12px;
}

.milestores-main .users-label input {
  width: 100%;
}

.milestores-main .users-form-info {
  margin-right: 0px !important;
}

/* .logo h1{
  font-size: 25px;
  margin-bottom: 0px;
  color: #fff;
} */

.input-container input {
  border: none;
  box-sizing: border-box;
  outline: 0;
  padding: .75rem;
  position: relative;
  width: 100%;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 1;
}

/* .add-remark-modal {
  margin: 0 !important
} */
.add-remark-modal .users-label input,
textarea {
  width: 100% !important;
}

.add-remark-modal .datepickericon {
  right: 3% !important;
}

.small-input .users-label input {
  width: 100% !important;
}

.w-50pr .users-label input {
  width: 85% !important;
}


.w-100pr .users-label input {
  width: 95% !important;
}

.w-100pr .datepickericon {
  right: 10% !important;
}

.w-50pr .datepickericon {
  right: 19% !important;
}

.status-select .users-label select {
  width: 100%;
  height: 42px !important;
  margin-top: 5px !important;
}

.finalquotation .users-label select {
  height: 42px !important;
}

.ml-37 {
  margin-left: 37px !important;
}

.small-input .users-label select {
  width: 100%;
  height: 42px;
  margin-top: 5px;
}

.small-input .upload-file {
  width: 100%;
  /* height: 50px !important; */
}

.small-input .w-470 .upload-file {
  width: 470px;
}

.small-input .users-label textarea {
  height: 108px;
}

.pr .small-input .users-label textarea {
  height: auto;
  margin-top: 3px;
}

.officersremarks .users-label {
  width: 550px;
  overflow: hidden;
  word-break: break-all;
}

.users-label select {
  /* height: 42px !important; */
  height: 38px !important;
}


.w-full-input input {
  width: 100%;
  height: 42px;
  font-size: 18px;
  line-height: 21px;
  color: #85878a;
  /* background: #e5e8ee; */
  border: 1px solid #c4c5c6 !important;
  /* box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.05) !important; */
  border-radius: 5px;
  margin-top: 5px;
}

.w-full-input label {
  font-weight: 600;
}

.bg-white {
  background: #FFFFFF;
  box-shadow: 0px 10px 30px rgb(17 38 146 / 5%);
  border-radius: 8px;
  padding: 20px;
}

.multi-select .css-1s2u09g-control {
  min-height: 42px;
}

.customer-width .css-b62m3t-container {
  min-height: 42px;
  width: 80%;
}

.customer-width .css-3iigni-container {
  min-height: 42px;
  width: 80%;
}

.multi-select .css-1pahdxg-control {
  min-height: 42px;
  border: 1px solid #c4c5c6 !important;
  box-shadow: none;
}

.ck.ck-dropdown .ck-dropdown__arrow {
  z-index: 0 !important;
}

.add-remark-modal .bill-type input {
  height: 15px !important;
  width: 15px !important;
}

.users-label .user-type.payment-type .form-control {
  width: 100% !important;
}

.multi-select label {
  font-weight: 600;
  margin-bottom: 5px !important;
}

.profile-list {
  position: absolute;
  top: 125%;
  right: 0%;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  padding: 10px;
  border-radius: 8px;
  width: 150px;
}

.profile-list p {
  font-size: 16px !important;
  margin-bottom: 10px !important;
}

.notification-list {
  position: absolute;
  top: 125%;
  right: 20%;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  padding: 10px;
  border-radius: 8px;
  width: 300px;
  border: 1px solid #06163a;
  border-bottom-width: thick;
  text-align: left;
  max-height: 313px;
  overflow-y: scroll;
}

/* / width / */
.notification-list::-webkit-scrollbar {
  width: 5px;
}

/* / Track / */
.notification-list::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* / Handle / */
.notification-list::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* / Handle on hover / */
.notification-list::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.notification-list p {
  font-size: 15px !important;
  margin-bottom: 10px !important;
  text-align: left;
}

.notification-list .card-list-div {
  border-bottom: 1px solid #06163a;
}

.notification-list .card-list-div:last-child {
  border-bottom: 0px solid #06163a;
}

.notification-count {
  background: red;
  border-radius: 50%;
  height: 21px;
  position: absolute;
  top: -5px;
  left: 31px;
  font-size: 15px !important;
  color: white !important;
  max-width: 43px;
  min-width: 20px;
  width: auto;
}

.w-88 {
  width: 88% !important;
}

.comment-box {
  margin-top: 5px;
  border-bottom: 1px solid #c4c4c4;
  padding: 0px 8px 8px;
}

.comment-box:last-child {
  border: 0 !important;
}

.row-border {
  border-bottom: 2px solid;
  padding-bottom: 50px;
}

.css-1okebmr-indicatorSeparator {
  display: none;
}

.Steps-header {
  font-size: 22px;
  /* font-weight: 600; */
  /* margin-top: 50px; */
}


.modal-90w {
  max-width: 90% !important;
}

.modal-70w {
  max-width: 70% !important;
}

.addEnquiry .small-input .users-label .select-icon {
  width: 100% !important;
}

.addEnquiry .small-input .users-label textarea {
  width: 100% !important;
}

.addEnquiry .start-estimated-date .users-label input {
  width: 92% !important;
}

.start-estimated-date .datepickericon {
  right: 12% !important;
}

.addEnquiry .start-estimated-date .users-label .form-select {
  width: 50% !important;
}

.addEnquiry .start-estimated-date .users-label .input-group {
  width: 80% !important;
}

.addEnquiry .start-estimated-date .users-label .input-group select {
  margin-top: 5px !important;
  border-radius: 5px 0px 0px 5px !important;
  border: 1px solid #c4c5c6 !important;
}

.addEnquiry .start-estimated-date .users-label .input-group button {
  margin-top: 5px !important;
  border-radius: 0px 5px 5px 0px !important;
  border: 1px solid #c4c5c6 !important;
}

.small-file-input {
  width: 80%;
}

.amount {
  min-width: 50%;
}

.css-1insrsq-control {
  min-height: 42px !important;
}

.addEnquiry .start-estimated-date .disabled-arrow .input-group select {
  border-radius: 5px !important;
}

/* ===========================================>Responsive<=========================================== */

@media (max-width: 1465px) {
  .adduser-btn {
    padding: 8px 15px !important;
  }
}

@media (min-width:991px) and (max-width:1400px) {
  .addEnquiry .start-estimated-date .users-label input {
    width: 80% !important;
  }

  .start-estimated-date .datepickericon {
    right: 23% !important;
  }
}

@media (max-width:991px) {
  .addEnquiry .users-label input {
    width: 100% !important;
  }

  .datepickericon {
    right: 2% !important;
  }

  .addEnquiry .users-label .select-icon {
    width: 100% !important;
  }

  .addEnquiry .users-label textarea {
    width: 100% !important;
  }

  .noc-form .upload-file {
    width: 100% !important;
  }

  .noc-form-btn {
    text-align: left;
  }

  .sidebar {
    position: absolute !important;
    width: 255px;
    z-index: 3;
  }

  .authlayout-dashborad-1 {
    grid-template-columns: auto;
  }

  .toggal-btn {
    margin-left: 255px;
  }

  .addEnquiry .start-estimated-date .users-label input {
    width: 100% !important;
  }

  .start-estimated-date .datepickericon {
    right: 3% !important;
  }

  .addEnquiry .start-estimated-date .users-label .input-group {
    width: 100% !important;
  }

  .small-file-input {
    width: 100%;
  }

}

@media (max-width:768px) {
  .ag-paging-panel {
    flex-direction: column !important;
  }

  .ag-theme-alpine .ag-paging-panel {
    padding: 15px 3px !important;
  }

  .ag-theme-alpine .ag-paging-panel {
    height: auto !important;
    padding: 15px;
  }

  .ag-paging-page-summary-panel {
    margin-top: 20px !important;
  }

  .ag-paging-row-summary-panel {
    margin-left: auto !important;
  }

  .set-pagination-dropdaun {
    position: absolute;
    bottom: 40px;
    left: 10px;
    display: flex;
  }
}

@media (max-width:574px) {
  .modal-90w {
    max-width: 100% !important;
  }

  .header-profile {
    display: none !important;
  }
}

@media (max-width:350px) {
  .upload-file-lable {
    margin-top: 12px;
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    font-weight: 600;
    color: #1D2226;
    cursor: pointer;
  }

}

.no-data-found {
  color: #1D2226;
  text-align: center;
  width: 100%;
  font-size: 20px;
  font-weight: 700;
}


/* ------------------- dashboard ------------------------- */
/* ----dashboard table------*/
.table-div {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  min-height: 551px;
  padding: 19px;
  border-radius: 8px;
  overflow: scroll;
}

#dashboard-table {
  text-align: center;
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  font-size: 20px;
}

#dashboard-table td,
#dashboard-table th {
  border: 1px solid #ddd;
  padding: 9px !important;
  font-size: 18px;
}

#dashboard-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

#dashboard-table tr:hover {
  background-color: #ddd;
}

#dashboard-table th {
  padding-top: 3px;
  padding-bottom: 3px;
  text-align: center;
  background-color: #d0cdcd;
  color: black;
}

.table-link {
  color: #0d6efd;
  font-weight: bold;
}

.table-link:hover {
  cursor: pointer;
}


/* ------dashboard card------- */
.card-div {
  width: 100%;
  height: 8.5rem;
  background-color: #FFFFFF;
  border: none;
  border-radius: 8px;
  display: flex;
}

.card-div .content {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1;
}

/* ------dashboard chart------- */
.chart-div {
  display: flex;
  position: relative;
  flex-direction: column;
  background-color: #fff;
  border: none;
  border-radius: 8px;
  padding: 10px;
  justify-content: center;
  align-items: center;
}

.chart-title {
  text-align: center;
  font-size: 1.5rem;
  font-weight: 600;
}

.chart {
  display: flex;
  justify-content: center;
}

.chart-div .select-chart {
  /* align-items: flex-end; */

  display: flex;
  align-items: center;
  flex-direction: row;
  align-self: flex-end;
}

.pie .select-chart {
  position: absolute;
  z-index: 2;
  top: 50px;
  right: 10px;
}

.pie .select-year {
  width: 180px;
}

/* ====================== view modal CSS -- Ravi C ====================== */

.text-center {
  text-align: center;
}

.vertical-top {
  vertical-align: text-top;
}

.viewer-modal .table,
td,
th,
.viewer-steps-table .table,
td,
th,
.viewer-project-modal .table,
td,
th .viewer-site-report-modal .table,
td,
th .viewer-pr-modal .table,
td,
th,
.viewer-product-modal .table,
td,
th {
  border: 1px solid #b2b2b2 !important;
  padding: 2px 5px 2px 5px;
}

.viewer-modal table,
.viewer-steps-table table,
.viewer-task-modal table,
.viewer-project-modal table,
.viewer-site-report-modal table,
.viewer-pr-modal table,
.viewer-product-modal table {
  width: 100% !important;
  border-collapse: collapse !important;
}

.viewer-modal tr td:first-child,
.viewer-task-modal tr td:first-child,
.viewer-project-modal tr td:first-child,
.viewer-site-report-modal tr td:first-child,
.viewer-pr-modal tr td:first-child,
.viewer-product-modal tr td:first-child,
.viewer-customer-address-modal tr td:first-child {
  font-weight: 700;
}

.viewer-task-modal tr td:first-child {
  width: 18%;
}

.viewer-steps-table tr td:nth-child(1),
.viewer-project-steps-table tr td:nth-child(1),
.viewer-project-pr-steps-table tr td:nth-child(1),
.viewer-employee-table tr td:nth-child(1) {
  width: 3%;
}

.viewer-steps-table tr td:nth-child(2) {
  width: 12%;
}

.viewer-steps-table tr td:nth-child(3) {
  width: 14%;
}

.viewer-steps-table tr td:nth-child(4) {
  width: 27%;
}

.viewer-steps-table tr td:nth-child(5) {
  width: 22%;
}

.viewer-steps-table tr td:nth-child(6) {
  width: 22%;
}


.viewer-project-steps-table tr td:nth-child(2) {
  width: 15%;
}

.viewer-project-steps-table tr td:nth-child(3) {
  width: 12%;
}

.viewer-project-steps-table tr td:nth-child(4) {
  width: 14%;
}

.viewer-project-steps-table tr td:nth-child(5) {
  width: 14%;
}

.viewer-project-steps-table tr td:nth-child(6) {
  width: 42%;
}

.viewer-employee-table tr td:nth-child(2) {
  width: 14%;
}

.viewer-employee-table tr td:nth-child(3) {
  width: 13%;
}

.viewer-employee-table tr td:nth-child(4) {
  width: 70%;
}

.viewer-site-report-modal tr td:first-child {
  width: 40%;
}

.viewer-site-report-modal-details tr td:first-child {
  width: 20%;
}

.viewer-noc-remarks-modal tr td:nth-child(1) {
  width: 20%;
}

.viewer-noc-remarks-modal tr td:nth-child(2) {
  width: 80%;
}

.viewer-customer-address-modal tr td:nth-child(1) {
  width: 20%;
}

.viewer-customer-address-modal tr td:nth-child(2) {
  width: 80%;
}

.status-table td {
  border: none !important;
}

.status-table th {
  border: none !important;
}

#dashboard-table .task-avatar {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.menu {
  width: 100%;
}

.menu:hover {
  font-weight: 900;
  color: white;
  /* font-size: 16px; */
  transform: scale(1.1);
}

.add-task-modal .ck-editor__editable {
  height: 200px
}

.atteched-files-section .container {
  margin-top: 50px;
  width: 100%;
  height: 100%;
}

.atteched-files-section img {
  width: 150px;
  height: 150px;
  left: 0;
}

.atteched-files-section .delete-icon {
  width: 22px !important;
  height: 22px !important;
  left: 0 !important;
  cursor: pointer;
}

.atteched-files-section .eye-icon {
  width: 22px !important;
  height: 22px !important;
  left: 0 !important;
  cursor: pointer;
}

.atteched-files-section .delete-icon-section {
  width: 30px !important;
  height: 30px !important;
  display: flex;
  justify-content: center;
  padding-top: 4px;
  background: #ffffff;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  right: 25px;
}

.atteched-files-section .view-icon-section {
  width: 30px !important;
  height: 30px !important;
  display: flex;
  justify-content: center;
  padding-top: 4px;
  background: #ffffff;
  border-radius: 50%;
  position: absolute;
  top: 36px;
  right: 25px;
}

.react-trello-card {
  max-width: 280px !important;
  min-width: 280px !important;
}

.avtar-kanban-task {
  margin: 2px 2px 2px 0 !important;
}

.date-kanban-task {
  font-size: 14px !important;
}

.react-trello-card header span {
  font-size: 14px !important;
}

.react-trello-card header {
  display: block !important;
}

.dashboard-amount-card .card-div {
  height: auto !important;
  padding: 10px;
  font-size: 1.4rem;
  font-weight: 600;
}

.purchase-request-tabel tbody tr td {
  padding: 5px !important;
}


/* Project Report <==== */

.projectreport-card {
  padding: 20px;
  background-color: #FFFFFF;
  border-radius: 15px;
  cursor: pointer;
  min-height: 192px;
}

.projectreport-card p {
  color: #212529;
  font-weight: bold;
  /* margin: 5px 0px; */
}

.projectreport-card p span {
  font-weight: 500;
}

.work-details span {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.status-switch input {
  width: 100px;
  height: 29px !important;
}

.status-switch input:checked[type=checkbox] {
  /* background-image: unset !important; */
  /* background-color: #FFFFFF !important; */
  background-repeat: no-repeat;
}

/* .steps-user-for .datepickericon {
  top: 38px !important;
  width: 20px;
} */

.prodat-status-switch .form-check-input {
  /* background-color: #D2001A !important; */
  background-color: #0c1239 !important;
  background-image: url(./assets/image/switch.svg) !important;

}

.prodat-status-switch .form-check-input:checked {
  background-color: green !important;
}

.left-arrows,
.right-arrows,
.left-line-arrow,
.right-line-arrow {
  font-family: "agGridAlpine";
}

.left-line-arrow::before {
  content: '\f115';
}

.right-line-arrow::before {
  content: '\f118';
}

.left-arrows::before {
  content: "\f125";
}

.right-arrows::before {
  content: '\f11f';
}


.pagination-back {
  display: flex;
  gap: 32px;
  position: absolute;
  right: 100px;
  bottom: 0;
  align-items: center;
}

.pagination-section {
  right: 0;
  bottom: 0;
}

.border-tops {
  border-top: 1px solid #dee1eb !important;
  position: absolute;
  bottom: 54px;
  width: 100%;
}

.left-arrow {
  margin-left: 8px;
  margin-right: 8px;
}

.pagination-number {
  display: flex;
  gap: 4px;
}

.pagination-number .number {
  font-weight: 600;
}

.pagination .page-link {
  background-color: transparent;
  border: 0;
  color: black;
  font-weight: 600;
  padding: 0;
  font-size: 12px;
}

.pagination .page-link:focus {
  background-color: transparent;
  box-shadow: none;
  color: black;
}

.pagination {
  gap: 5px;
}

.pagination .text-link {
  color: #222121;
  font-weight: 400;
}

.pagination .page-link:hover {
  background-color: transparent;
  color: black;
}

.ag-theme-alpine {
  line-height: unset !important;
}

.sale-datetpicker .react-datepicker__input-container input {
  padding: .375rem .75rem;
  border-radius: 7px;
}

.sale-datetpicker .date-picker {
  display: flex;
  align-items: center;
  gap: 10px;
}

.saling_date .form-control {
  appearance: auto;
  cursor: pointer;
}

.quotation-edit-section {
  display: flex;
  align-items: center;
  gap: 25px;
  padding-top: 25px;
}

.quotation-edit-section .edit-icon {
  width: 15px;
  height: 15px;
  cursor: pointer;
}

.icon-details {
  display: flex;
  gap: 10px;
}

.remarks-textare .users-label textarea {
  height: 41px;
}

.dropdown-modal .dropdown-toggle {
  width: 0;
  background-color: transparent;
  border: 0;
}

.dropdown-modal .btn:hover {
  background-color: transparent !important;
}

.dropdown-modal .btn.show {
  color: black !important;
  background-color: transparent !important;
  border: 0 !important;
}

.dropdown-modal .dropdown-toggle::after {
  border-top: 0 !important;
}

.radio-button input {
  height: 17px;
  width: 17px;
}

.radio-button .registered-button {
  gap: 7px;
  align-items: center;
}

.total-price-details {
  border: 3px solid #dfdfdf;
  padding: 15px;
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
}

.ag-theme-balham-dark .ag-root-wrapper {
  background-color: white !important;
  color: black;
}

.ag-theme-balham-dark .ag-header-cell-text {
  color: black !important;
}

.ag-theme-balham-dark .ag-cell {
  color: black !important;
}

.enquery-datepicker .react-datepicker-wrapper {
  width: 100%;
}

.project-edit-section .addEnquiry .start-estimated-date .users-label input {
  width: 100% !important;
}

.project-edit-section .addEnquiry .start-estimated-date .users-label .input-group {
  width: 100% !important;
}

.project-edit-section .form-control:disabled {
  background-color: transparent;
}

.enquiry-textarea textarea {
  height: unset !important;
}

.ag-header-icon {
  display: none;
}

.final-material-modal .set-pagination-dropdaun {
  bottom: 13px;
}

.final-material-modal-title {
  flex-wrap: wrap;
  gap: 20px;
}

.addEnquiry .users-label input {
  width: 100% !important;
}

.addEnquiry .users-label .select-icon {
  width: 100% !important;
}

.addEnquiry .datepickericon {
  right: 4% !important;
}

.generate-quatation-modal {
  margin: 20px;
}

.daily-sitereport-section .users-label textarea {
  height: unset !important;
  width: 100% !important;
}

.project-edit-section .customer-width .css-b62m3t-container {
  width: 100%;
}

.approve-enquiry-section .customer-width .css-b62m3t-container {
  width: 100% !important;
}

.approve-enquiry-section .approve_customer .css-b62m3t-container {
  width: 100% !important;
}

.noc-form .upload-file {
  width: 100% !important;
}

.approve_customer .css-b62m3t-container {
  width: 100% !important;
}

/* .final-material-table .ag-row-even{
  height: 45px !important;
} */

.sub-table .ag-details-row {
  padding: 15px !important;
}

.sub-table .ag-header-cell {
  width: 150px !important;
}

.sub-table .ag-details-row .ag-header-icon {
  display: block !important;
  cursor: pointer;
}

.sub-table .ag-details-row .ag-icon-filter {
  display: none;
}

.sub-table .ag-details-row .ag-popup .ag-icon-filter {
  display: block !important;
}

.sub-table .ag-root-wrapper {
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
}

.search-button {
  padding: 0.500rem 0.75rem !important;
  height: 38px;
}

.subproduct-modal .modal-content {
  width: 90%;
  margin: 0 auto;
}

.chart-div .select-chart {
  position: unset;
}

.over-flow-scroll {
  max-height: 450px;
  overflow-y: auto;
  overflow-x: hidden;
}

select {
  padding: 0px 4px;
}

.generate-section .dropdown-toggle {
  display: flex;
  align-items: center;
}

.css-13cymwt-control {
  overflow: visible !important;
}

.generate-section .dropdown-toggle::after {
  margin-left: 0 !important;
}

/* ==================MEDIA============ */

@media (max-width:1242px) {
  .final-material-button {
    flex-wrap: wrap;
    margin-top: 10px;
  }

  .final-material-button button {
    margin-bottom: 10px;
  }

}

@media (max-width:534px) {
  .chart-div .round-chart {
    display: block !important;
  }

  .chart-div .select-chart {
    margin-top: 10px;
  }
}

@media (max-width:425px) {
  .users-form {
    padding: 12px 12px 20px;
  }

  .generate-quatation-modal {
    margin: 0 !important;
  }
}

/* .ag-group-contracted.ag-hidden {
  display: unset !important;
} */

/* .users-form-info .users-label .assign-person-select {
  width: 80% !important;
} */

.dropdown-toggle::after {
  margin-left: unset !important;
}

.table>:not(caption)>*>* {
  background-color: unset !important;
}

.css-13cymwt-control{
  min-height: 50px;
}

.dashboard-amount-card .card-div h3 {
  font-weight: 600;
}

.dashboard-amount-card .card-div h3 {
  font-size: 20px;
  margin-bottom: 0;
}

.stock-state-value {
  position: absolute;
  top: 15px;
  right: 15px;
}

.quotation-section {
  border-bottom: 1px solid lightgray;
  padding-bottom: 20px;
}

.quotation-section input {
  outline: none;
  padding-left: 10px;
}

.multi-select .css-13cymwt-control {
  min-height: unset;
}

.add-purchase-scrollbar {
  min-height: calc(100vh - 30rem);
  max-height: calc(100vh - 14rem);
}

@media  (max-width: 1439px) {
  .dashboard-amount-card .card-div h3 {
    font-size: 16px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .font-size-lg {
    font-size: 14px;
  }  
}
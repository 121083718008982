body {
  background-color: #E5E8EE !important;
}

.title h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 50px;
  line-height: 34px;
  color: #1f2937;
  margin-bottom: 33px;
}

.users-form {
  width: auto;
  /* min-height: 650px; */
  background: #ffffff;
  box-shadow: 0px 10px 30px rgba(17, 38, 146, 0.05);
  border-radius: 8px;
  padding: 30px 30px 46px;
}

/* .users-form-info {
  margin: 0px 40px;
} */
/* .users-form-info .multi-select {
  min-height: 42px;
  width: 80%;
} */

.users-label label {
  font-weight: 600;
}

.users-label input {
  /* width: 525px; */
  /* height: 42px; */
  height: 38px;
  font-size: 16px;
  line-height: 21px;
  /* color: #85878a; */
  /* background: #e5e8ee; */
  border: 1px solid #c4c5c6 !important;
  /* box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.05) !important; */
  border-radius: 5px;
  margin-top: 5px;
}

.react-datepicker-ignore-onclickoutside:focus {
  outline: none !important;
}

.users-label textarea {
  width: 525px;
  height: 150px;
  font-size: 18px;
  line-height: 21px;
  /* color: #85878a; */
  /* background: #e5e8ee; */
  border: 1px solid #ced4da;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.05) !important;
  border-radius: 5px;
  margin-top: 10px;
}

.users-label textarea:focus {
  border: 1.5px solid #3d76cc !important;
}

.users-label input:focus {
  /* box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.05) !important; */
  border: 1.5px solid #3d76cc !important
}

.users-label input:focus-visible {
  outline: none;
}

.users-label select:focus {
  /* box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.05) !important; */
  border: 1.5px solid #3d76cc !important
}

.users-label img {
  position: absolute;
  right: 3%;
  top: 35px;
}

.users-label select {
  width: 525px;
  height: 52px;
  font-size: 18px;
  line-height: 21px;
  /* color: #85878a; */
  border: 1px solid #ced4da;
  /* background: #e5e8ee; */
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  margin-top: 5px;
}

.users-label select:focus {
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.05) !important;
}

.rotateArrow {
  transform: rotate(180deg);
}

.save-button {
  font-size: 14px;
  line-height: 23px;
  color: #FFFFFF;
  height: 47px;
  width: 120px;
  /* padding: 12px 35px; */
  background: #06163A;
  border-radius: 3px;
  border: none;
  margin-right: 10px;
}

.cancel-button {
  /* padding: 12px 30px; */
  font-size: 14px;
  line-height: 23px;
  color: #06163A;
  height: 37px;
  width: 90px;
  border-radius: 3px;
  border: 1px solid #06163A;
  background-color: transparent;
}

.table thead {
  height: 55px;
  vertical-align: middle !important;
  background: #e3e3e3;
  box-shadow: 0px 10px 30px rgba(17, 38, 146, 0.05);
  border-radius: 8px 8px 0px 0px;
}

.table thead tr td {
  font-weight: bold;
  font-size: 25px;
  line-height: 34px;
  color: #1f2937;
  line-height: 80px;
}

tbody tr td {
  padding: 18px 0 !important;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  color: #1f2937;
}

tbody,
td,
tfoot,
th,
thead,
tr {
  border-color: unset !important;
  border-width: unset !important;
}

tbody,
td,
tfoot,
th,
thead,
tr {
  border: none !important;
}

/* ======================Enquiry page=================== */

.enquir-table {
  background: #e5e8ee !important;
  height: calc(100vh - 88px);
}

.tabel-header-content {
  display: flex;
  justify-content: space-between;
  padding: 20px 20px 0px;
}

.tabel-header-content h1 {
  font-family: Nunito;
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
}

.dropdown-bg {
  background: transparent !important;
}

.dropdown-content {
  width: 160px;
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  background: transparent !important;
  color: #1f2937 !important;
  border-color: #1d2226 !important;
}

.dropdown-content:focus {
  box-shadow: none !important;
}

.dropdown-toggle::after {
  display: inline-block;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.4em solid !important;
  border-right: 0.4em solid transparent !important;
  border-bottom: 0;
  border-left: 0.4em solid transparent !important;
  margin-left: 92px !important;
}

.table-content {
  margin: 20px !important;
}

.table-head {
  width: 100%;
  height: auto !important;
  background: #e3e3e3;
  box-shadow: 0px 10px 30px rgba(17, 38, 146, 0.05) !important;
  text-align: center;
}

.table-main {
  border-radius: 10px !important;
  overflow: hidden;
}

.table-head th {
  padding: 15px 0px !important;
}

.tbody-bg {
  background: #ffffff;
  box-shadow: 0px 10px 30px rgba(17, 38, 146, 0.05);
  border-radius: 8px;
  text-align: center;
}

.tbody-bg-span {
  float: left;
  margin-left: 10px;
}

.approve-btn {
  background: #06163A;
  border-radius: 3px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #ffffff;
  border: none !important;
  /* margin-right: 5px; */
  padding: 2px 12px;
}

.reject-btn {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  padding: 2px 12px;
  color: #1d2226;
  border: 1px solid #1f2937;
  box-sizing: border-box;
  border-radius: 3px;
  background: transparent !important;
  /* float: right; */
  margin-right: 10px;
}

.tbody-bg td {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: #1f2937;
}

/* ============pagination=============== */
.table-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  padding: 5px 10px;
}

.table-scroll-height {
  height: 615px;
}

.table-scroll-y {
  overflow-y: auto;
}

.table-scroll-y::-webkit-scrollbar {
  width: 5px;
}

/* .table-scroll-y::-webkit-scrollbar-button {
  background: #000;
} */

.pagination {
  display: flex;
  /* padding: 1rem !important; */
  /* background-color: #f5f5f5; */
  width: fit-content;
  border-radius: 0.8rem;
  float: right;
}

.crumbs {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
  gap: 0.5rem;
}

.crumb {
  display: block;
  text-decoration: none;
  color: currentColor;
  border-radius: 0.2rem;
  border: 0.0625rem solid #d7d7d7;
  /* 1px */
  padding: 1px 15px;
  font-size: 14px;
}

.crumb:focus,
.crumb:hover {
  background-color: transparent !important;
  color: #1d2226;
  border-color: #1d1f20;
}

/* .crumb__prev{
  font-size: 20px;
} */
.addUser-btn {
  width: 186px;
  border: 2px solid #1f2937;
  box-sizing: border-box;
  border-radius: 8px;
  outline: none;
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #1f2937;
}

.adduser-save-cancel-btn {
  width: 100%;
  margin-top: 200px;
  padding-right: 100px;
  height: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.crumb__prev {
  margin-right: 0.5rem;
}

.crumb__next {
  margin-left: 0.5rem;
}

.form-control {
  box-shadow: none !important;
}


/* =======================pages-selec====================== */

.pages-selec {
  background: transparent;
  /* border: none; */
  height: 25px;
  margin: 0px 15px;
}



/* =====================Project==================== */

.edit-btn {
  border: none;
  background: transparent;
  margin-right: 15px;
}

.delete-btn {
  border: none;
  background: transparent;
  /* margin-right: 15px; */
}


.upload-file {
  /* background-color: #E5E8EE; */
  border: 1px solid #c4c5c6 !important;
  text-align: center;
  width: 100%;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 42px; */
  height: 38px;
}

.upload-file-lable {
  /* margin-top: 9px; */
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  font-weight: 600;
  color: #1D2226;
  cursor: pointer;
}

/* .width-410{
  width: 410px !important;
} */

.width-371 {
  width: 371px !important;
}

/* NOC===================================== */

.noc-form-btn {
  text-align: right;
}


/* ==============================Approve Enquiry================================= */

.Milestores-title {
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  color: #1D2226;
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 0px !important;
}

.Milestores-add-btn {
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #ffffff;
  padding: 12px 45px;
  background: #e51916;
  border-radius: 3px;
  border: 0;
  margin-right: 10px;
  /* margin-left: auto; */
  text-align: end;
  /* margin-right: 50px; */
}


/* ===================================>Redesign<=================================== */
/* ===================================>Add Enquiry<=================================== */

.addEnquiry .users-label input {
  width: 80% !important;
}

.addEnquiry .upload-file input {
  width: 80% !important;
}

.noc-form .upload-file {
  width: 80% !important;
}

.addEnquiry .users-label .select-icon input {
  width: 100% !important;
}

.addEnquiry .users-label .select-icon {
  width: 80% !important;
}

/* .addEnquiry .users-label textarea {
  width: 80% !important;
} */

.addEnquiry .description-label textarea,
.addEnquiry .description-label input {
  width: 100% !important;
}

.addEnquiry .users-label select {
  width: 100% !important;
}

/* .addEnquiry .datepickericon {
  right: 27%;
} */

/* ====================================>Dashboard<==================================== */

.set-page-heade {
  background: #FFFFFF;
  box-shadow: 0px 10px 30px rgba(17, 38, 146, 0.05);
  border-radius: 8px;
  padding: 20px;
}

.page-heade-link {
  color: #1F2937;
  text-decoration: none !important;
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 34px;
}

.page-heade-link:hover {
  color: #1f2937;
}

.logo img {
  width: 100%;
  border-radius: 6px;
}

.logout-button {
  background-color: transparent;
  border: none !important;
  color: rgba(255, 255, 255, 0.65);
}


/* ==>date-picoker<== */

.react-datepicker__header {
  background-color: #06163A !important;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  color: #fff !important;
}

.react-datepicker__day-name {
  color: #fff !important;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
  border-bottom-color: #06163a !important;
}

.react-datepicker {
  border: 1px solid #06163a !important;
}


.projectedit .users-form {
  height: auto !important;
  min-height: 30px;
}

input:focus {
  border: 1px solid #3d76cc;
}


/* .users-form .users-form-body{
  min-height: 550px;
} */

.btn-link {
  font-weight: 400 !important;
  color: red !important;
  text-decoration: none !important;
  border: 1px solid !important;
  box-shadow: none !important;
}

.btn-success {
  min-width: 95px !important;
}


/* ===========================>dashboard-fack-text<=========================== */

.dashboard-fack-text {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 150px;
}

.dashboard-fack-text h1 {
  font-size: 48px;
  font-weight: bold;
  color: #06163a38;
}

/* .setinputwidth input{
  width: 525px !important;
} */

.select-icon img {
  position: absolute;
  right: 3%;
  top: 10px;
  height: 18px;
  width: 18px;
}

.datepicker-input[disabled] {
  background-color: #e9ecef;
  opacity: 1;
}

.users-label .user-type select {
  width: 100% !important;
  height: 42px !important;
  margin-top: 5px !important;
}

.billType .user-type select {
  width: 100% !important;
}

/* .select-icon.custom img{
  position: absolute;
  top: 13px;
  right: 25%;
} */

.table-label {
  font-size: 20px;
  font-weight: 600;
}

/* ===========================>wront-error page<=========================== */

.wront-error {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.wront-error h1 {
  font-weight: 600;
  font-size: 44px;
  line-height: 34px;
  color: #1F2937;
  margin-top: 30px;
}

.wront-error p {
  font-weight: 400;
  font-size: 24px;
  line-height: 34px;
  margin-top: 10px;
  color: #1F2937;
}

.selector_label {
  font-size: 16px;
  font-weight: 600;
}

.approve_customer .css-b62m3t-container {
  width: 75% !important;
}

*::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #F5F5F5;
}

*::-webkit-scrollbar {
  width: 3px;
  height: 5px;
  background-color: #F5F5F5;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #1f3158 !important;
}

.types_input input {
  width: 15px;
  height: 15px;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6;
}

.product_ledger td {
  font-weight: unset !important;
  padding-left: 10px !important;
}

.type_of_product label {
  font-size: 20px;
}

.type_of_product p {
  font-size: 20px;
  /* margin-left: 8px; */
}

.type_of_product span {
  font-weight: 400 !important;
}

.payment-ammount-confirmation-body {
  text-align: center !important;
}

.payment-ammount-confirmation-body .form-switch .form-check-input {
  width: 4rem !important;
}

.FinalModal {
  filter: blur(2px);
}

/* for custom_switch_status */

#custom_switch_status {
  width: 60px !important;
}

.css-b62m3t-container{
  overflow: visible !important;
}